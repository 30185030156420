import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ "list", "dynamicTitle", "prefix", "separator", "digits", "index", "withMonth", "withYear", "toggleButton", "inputDefault", "year", "month", "form", "documentType", "invoiceForm", "quotationForm", "establishmentId", "errorNumberingLength", "onboardingTag"]

  connect() {
      if(this.hasListTarget){
          this.sortable = Sortable.create(this.listTarget, {
              onEnd: this.end.bind(this)
          })
          this.monthOrYearActive()
          this.dynamicNumbering()
      }
  }

  end(event){
      this.dynamicNumbering()
  }

  dynamicNumbering(event){
      const date = new Date()
      const currentYear = date.getFullYear();
      const currentMonth = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);

      let elementArray = this.sortable.toArray()

      let elementOrder = [];

      for(let i = 0; i < elementArray.length; i++){
          switch(elementArray[i]){
              case "prefix":
                  elementOrder.push(this.prefixTarget.value)
                  break;
              case "year":
                  if(this.withYearTarget.value == "true")
                  {
                      elementOrder.push(currentYear)
                  }
                  else{
                    elementOrder.splice(i)
                  }
                  break;
              case "month":
                  if(this.withMonthTarget.value == "true")
                  {
                      elementOrder.push(currentMonth)
                  }
                  else{
                    elementOrder.splice(i)
                  }
                  break;
              case "document_number":
                  elementOrder.push(this.indexTarget.value.toString().padStart(this.digitsTarget.value, "0"))
                  break;
          }
      }

      this.dynamicTitleTarget.innerHTML = elementOrder.join(this.separatorTarget.value)

      if(this.hasErrorNumberingLengthTarget){
          if(this.dynamicTitleTarget.innerHTML.length > 17){
              this.errorNumberingLengthTarget.classList.remove("hidden")
          }
          else{
              this.errorNumberingLengthTarget.classList.add("hidden")
          }
      }
  }

  monthOrYearActive(){
      if(this.withMonthTarget.value == "true"){
          this.monthTarget.classList.remove("hidden")
      }
      else{
          this.monthTarget.classList.add("hidden")
      }

      if(this.withYearTarget.value == "true"){
          this.yearTarget.classList.remove("hidden")
      }
      else{
          this.yearTarget.classList.add("hidden")
      }

      this.dynamicNumbering()
  }

  submit(){
      this.formTarget.requestSubmit()
  }


  changeDocument(){
      if (this.hasOnboardingTagTarget) {
          switch (this.documentTypeTarget.value){
              case "Quotation":
                  window.location.href = `/onboarding/settings/quotation_numberings?establishment_id=${this.establishmentIdTarget.value}`
                  break;
              case  "Invoice":
                  window.location.href = `/onboarding/settings/numberings?establishment_id=${this.establishmentIdTarget.value}`
                  break;
               case  "Credit_note":
                    window.location.href = `/onboarding/settings/credit_note_numberings?establishment_id=${this.establishmentIdTarget.value}`
                break;
          }
      }
      else{
          switch (this.documentTypeTarget.value){
              case "Quotation":
                  window.location.href = `/settings/quotation_numberings?establishment_id=${this.establishmentIdTarget.value}`
                  break;
              case  "Invoice":
                  window.location.href = `/settings/numberings?establishment_id=${this.establishmentIdTarget.value}`
                  break;
              case  "Credit_note":
                  window.location.href = `/settings/credit_note_numberings?establishment_id=${this.establishmentIdTarget.value}`
                  break;
          }
      }
  }
}
