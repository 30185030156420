const isUJS = (link) => {
  return (
    link.hasAttribute("data-remote") ||
    link.hasAttribute("data-behavior") ||
    link.hasAttribute("data-method") ||
    link.hasAttribute("data-confirm")
  );
};

const isTurboStream = (link) => {
  return link.hasAttribute("data-turbo-stream");
};

const isSavingData = () => {
  return navigator.connection?.saveData;
};

const hasSlowInternet = () => {
  return (
    navigator.connection?.effectiveType === "slow-2g" ||
    navigator.connection?.effectiveType === "2g" ||
    navigator.connection?.effectiveType === "3g"
  );
};

document.addEventListener("turbo:before-prefetch", (event) => {
  if (
    isUJS(event.target) ||
    isTurboStream(event.target) ||
    isSavingData() ||
    hasSlowInternet()
  )
    event.preventDefault();
});
