import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = [ "inputFile", "textContainer" ]
  
  handleDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  handleDragLeave(event) {
    event.preventDefault();
  }

  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.uploadFiles(files);
  }

  handleFile(event) {
    const files = event.target.files;
    this.uploadFiles(files);
  }

  uploadFiles(files) {
    this.inputFileTarget.files = files;
    let fileName = this.inputFileTarget.value.split("\\").pop();
    this.textContainerTarget.children[0].innerHTML = fileName;
  }
}
