import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "totalAmountHT",
        "totalAmountHTShow",
        "totalBilled",
        "totalAmountTTCShow",
        "totalTVAShow",
        "totalGrossChargeAmount",
        "totalAmount",
        "totalNetChargeAmount",
        "discountValue",
        "totalDiscount",
        "chargeValue",
        "totalCharge",
        "vatValue",
    ]

    connect(){
    }

    calculateTotalAmount(){
        // Fonction pour calculer un prix total
        function calculate(array, targets, show, variable, initPrice){

            if(targets.length > 1){
                setTimeout(function(){
                    for (const item of targets){
                            let value = item.getAttribute("data-value")
                            array.push(parseFloat(value));
                    }
                    for(let i = 0; i < array.length; i++){
                        variable += array[i];
                    }
                    show.innerHTML = variable.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2
                    });
                    show.value = variable.toFixed(2);
                    return variable;

                }, 500)
            }
            else{
                setTimeout(function(){
                        let value = initPrice.getAttribute("data-value")
                        show.innerHTML = parseFloat(value).toLocaleString("fr-FR", {
                                style: "currency",
                                currency: "EUR",
                                minimumFractionDigits: 2
                        });
                }, 500)
            }
        }

        // CALCULER LE PRIX TOTAL HT
        let totalAmountsHTArray = [];
        let priceHT = 0;
        calculate(totalAmountsHTArray, this.totalNetChargeAmountTargets, this.totalAmountHTShowTarget, priceHT, this.totalNetChargeAmountTarget);
        //CALCULER LE PRIX TOTAL TTC
        let totalAmountsTTCArray = [];
        let priceTTC = 0;
        calculate(totalAmountsTTCArray, this.totalAmountTargets, this.totalAmountTTCShowTarget, priceTTC, this.totalAmountTarget);

        // CALCULER TOTAL REMISE
       function calculateDiscountAndCharge(targets, show){
           let array = []
           let variable = 0;

           for(const item of targets){
               array.push(item.value)
           }

           for(let i = 0; i < array.length; i++){
               parseFloat(variable += parseFloat(array[i]));
           }

           show.innerHTML = variable.toLocaleString("fr-FR", {
               style: "currency",
               currency: "EUR",
               minimumFractionDigits: 2
           });
        }

        calculateDiscountAndCharge(this.discountValueTargets, this.totalDiscountTarget)
        calculateDiscountAndCharge(this.chargeValueTargets, this.totalChargeTarget)
        setTimeout(()=>{
            calculateDiscountAndCharge(this.vatValueTargets, this.totalTVAShowTarget)
        }, 700)
    }
}
