import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selectField", "periodField", "invoiceText"]

    connect() {
    }

    billingMethod(){
        let selectValue = this.selectFieldTarget.value
        let periodFieldValue = parseInt(this.periodFieldTarget.value)
        let invoiceText = this.invoiceTextTarget

        const unique = "unique"
        const weekly = "weekly"
        const montly = "monthly"
        const quaterly = "quaterly"
        const biAnnual = "bi-annual"
        const annual = "annual"

        switch (true) {
            case (selectValue === unique) :
                periodFieldValue = 1
                invoiceText.innerHTML = "La facturation sera générée uniquement à la création de la facture"
                break;
            case (selectValue === weekly && periodFieldValue === 1) :
                invoiceText.innerHTML = "La facturation sera générée toutes les semaines"
                break;
            case (selectValue === weekly && periodFieldValue === 4) :
                this.selectFieldTarget.selectedIndex = 2
                this.periodFieldTarget.value = 1
                invoiceText.innerHTML = "La facturation sera générée tous les mois"
                break;
            case (selectValue === weekly && periodFieldValue > 0    ) :
                invoiceText.innerHTML = `La facturation sera générée toutes les ${periodFieldValue} semaines`
                break;
            case (selectValue === montly && periodFieldValue === 1) :
                invoiceText.innerHTML = "La facturation sera générée tous les mois"
                break;
            case (selectValue === montly && periodFieldValue === 3) :
                this.selectFieldTarget.selectedIndex = 3
                this.periodFieldTarget.value = 1
                invoiceText.innerHTML = "La facturation sera générée tous les trimestres"
                break;
            case (selectValue === montly && periodFieldValue > 0) :
                invoiceText.innerHTML = `La facturation sera générée tous les ${periodFieldValue} mois`
                break;
            case (selectValue === quaterly && periodFieldValue === 1) :
                invoiceText.innerHTML = "La facturation sera générée tous les trimestres"
                break;
            case (selectValue === quaterly && periodFieldValue === 2) :
                this.selectFieldTarget.selectedIndex = 4
                this.periodFieldTarget.value = 1
                invoiceText.innerHTML = "La facturation sera générée tous les semestres"
                break;
            case (selectValue === quaterly && periodFieldValue > 0) :
                invoiceText.innerHTML = `La facturation sera générée tous les ${periodFieldValue} trimestres`
                break;
            case (selectValue === biAnnual && periodFieldValue === 1) :
                invoiceText.innerHTML = `La facturation sera générée tous les semestres`
                break;
            case (selectValue === biAnnual && periodFieldValue === 2) :
                this.selectFieldTarget.selectedIndex = 5
                this.periodFieldTarget.value = 1
                invoiceText.innerHTML = "La facturation sera générée tous les ans"
                break;
            case (selectValue === biAnnual && periodFieldValue > 0) :
                invoiceText.innerHTML = `La facturation sera générée tous les ${periodFieldValue} semestres`
                break;
            case (selectValue === annual && periodFieldValue === 1) :
                invoiceText.innerHTML = "La facturation sera générée tous les ans"
                break;
            case (selectValue === annual && periodFieldValue > 0) :
                invoiceText.innerHTML = `La facturation sera générée tous les ${periodFieldValue} ans`
                break;
            default:
        }
    }

}

