import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "siret",
    "city",
    "lineOne",
    "lineTwo",
    "lineThree",
    "postalCode",
    "name",
    "siren"
  ]

  connect() {
    this.autocomplete()
  }

  autocomplete(){
    if(this.hasSirenTarget){
      fetch(`/legal_units/establishment_complete?q=${this.sirenTarget.value}`,
      {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          this.siretTarget.value = data[0].siret
          this.lineOneTarget.value = data[0].geo_l4
          this.postalCodeTarget.value = data[0].code_postal
          this.cityTarget.value = data[0].libelle_commune
        })
    }
  }
}
