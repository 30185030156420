/* eslint no-console:0 */

import "@hotwired/turbo-rails";

require("@rails/activestorage").start();
require("local-time").start();

// BillyGen
import "./pagy.js";
import "@rails/actiontext";
import "chartkick/chart.js";
import "flowbite/dist/flowbite.turbo.js";
import "trix";

import "./channels";
import "./controllers";
import "./src/**/*";
