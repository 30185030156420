import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-new-customer-individual-entity"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_new_customer_individual_entity: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#title",
          popover: {
            description: "Veuillez choisir la civilité du client particulier.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#name",
          popover: {
            description:
              "Entrez son nom et son prénom dans les champs respectifs.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#details",
          popover: {
            description:
              "Insérez ses coordonnées : email et numéro de téléphone dans les champs respectifs",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#address",
          popover: {
            description:
              "Insérez le Numéro et la voie de l'adresse du contact du contact",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#postal_code",
          popover: {
            description: "Insérez le Code Postal du contact",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#city",
          popover: {
            description: "Insérez la Ville du contact",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#country_code",
          popover: {
            description: "Insérez le Pays du contact",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#state",
          popover: {
            description: "Insérez la Région/Province/Etat du contact",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#next",
          popover: {
            description:
              'Cliquez ensuite sur "Suivant" pour valider, enregistrer les informations renseignées et passer à l\'étape suivante.',
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
