import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
    static targets = [ "withMonth", "withYear", "fadein", "bg", "fadeout", "toggleButton", "year", "month"]

    connect() {
        this.activeYear();
        this.activeMonth();
    }

    addYearOrMonth(target){
        if(target.value === "true"){
            target.value = "false"
        }
        else{
            target.value = "true"
        }
    }

    addMonth(){
        if(this.hasWithMonthTarget){
            this.addYearOrMonth(this.withMonthTarget)
        }
    }

    addYear(){
        if(this.hasWithYearTarget){
            this.addYearOrMonth(this.withYearTarget)
        }
    }

    activeMonth(){
        if(this.hasWithMonthTarget){
            if(this.withMonthTarget.value === "true"){
                this.buttonActive(this.toggleButtonTarget, this.bgTarget, this.fadeinTarget, this.fadeoutTarget)
            }
        }
    }

    activeYear(){
        if(this.hasWithYearTarget){
            if(this.withYearTarget.value === "true"){
                this.buttonActive(this.toggleButtonTarget, this.bgTarget, this.fadeinTarget, this.fadeoutTarget)
            }
        }
    }


    buttonActive(target, bg, fadein, fadeout){
        target.classList.add("bg-secondary-500")
        bg.classList.add("translate-x-5")
        fadeout.classList.add("opacity-0")
        fadeout.classList.add("ease-out")
        fadeout.classList.add("duration-100")
        fadein.classList.add("ease-out")
        fadein.classList.add("duration-100")
        fadein.classList.add("opacity-100")
        target.classList.remove("bg-gray-200")
        fadein.classList.remove("opacity-0")
        bg.classList.remove("translate-x-0")
        fadeout.classList.remove("opacity-100")
        fadeout.classList.remove("ease-in")
        fadeout.classList.remove("duration-200")
        fadein.classList.remove("ease-in")
        fadein.classList.remove("duration-200")
    }

    buttonInactive(target, bg, fadein, fadeout){
        target.classList.add("bg-gray-200")
        bg.classList.add("translate-x-0")
        fadeout.classList.add("opacity-100")
        fadeout.classList.add("ease-in")
        fadeout.classList.add("duration-200")
        fadein.classList.add("opacity-0")
        fadein.classList.remove("opacity-100")
        fadein.classList.add("ease-in")
        fadein.classList.add("duration-200")
        bg.classList.remove("translate-x-5")
        target.classList.remove("bg-secondary-500")
        fadeout.classList.remove("opacity-0")
        fadeout.classList.remove("ease-out")
        fadeout.classList.remove("duration-100")
        fadein.classList.remove("ease-out")
        fadein.classList.remove("duration-100")
    }

}
