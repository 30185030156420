import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ 'buttons' ]

	connect() {
	}

	toggle(event) {
		const old_selected_button = this.buttonsTarget.querySelector('.b-btn-selected')
    if(old_selected_button !== null) {
      old_selected_button.classList.remove('b-btn-selected');
      old_selected_button.classList.add('b-btn-select');
    }

		const new_selected_button = event.target.closest('label');
		new_selected_button.classList.add('b-btn-selected');
		new_selected_button.classList.remove('b-btn-select');
	}
}

