import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = [ "dateContainer", "radio" ]

  connect() {
    this.toggleCustomDate()
  }

  toggleCustomDate(){
    let radio = this.radioTargets.find(radio => radio.checked)

    if (!radio) return

    if (radio.value == 'custom') {
      this.dateContainerTarget.classList.remove('hidden')
    } else {
      this.dateContainerTarget.classList.add('hidden')
    }
  }
}
