import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "checkbox", "documentIds", "button"]

  toggle(e) {
    event.preventDefault()
    if(!this.buttonTarget.classList.contains("b-btn-disabled")){
      this.containerTarget.classList.toggle("hidden")
    }
  }

  updateDocumentsIds(){
    const selectedDocuments = this.checkboxTargets.filter(checkbox => checkbox.checked)
    const documentIds = selectedDocuments.map(checkbox => checkbox.value)
    this.documentIdsTarget.value = documentIds
  }
}
