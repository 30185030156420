import { Controller } from "@hotwired/stimulus";
import Trix from "trix";

export default class extends Controller {
  static targets = ["subjectInput"];
  connect() {
    this.lastFocusedInput = null;
    this.trixInput = this.element.querySelector("trix-editor");
    this.trixInput.addEventListener("focus", () => {
      this.lastFocusedInput = this.trixInput;
    });
    if (this.hasSubjectInputTarget) {
      this.subjectInputTarget.addEventListener("focus", () => {
        this.lastFocusedInput = this.subjectInputTarget;
      });
    }
  }

  get editor() {
    return this.trixInput.editor;
  }

  insertTag(event) {
    const tag = event.target.getAttribute("data-tag");
    if (this.hasSubjectInputTarget) {
      if (this.lastFocusedInput === this.subjectInputTarget) {
        this.subjectInputTarget.value = this.subjectInputTarget.value + tag;
        return;
      }
    }
    this.editor.insertString(tag);
  }
}
