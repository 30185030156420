import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputFile", "textContainer", "errorContainer"];

  connect() {
    this.element.addEventListener("submit", this.showError.bind(this));
  }

  inputChange() {
    let fileName = this.inputFileTarget.value.split("\\").pop();
    this.textContainerTarget.children[0].innerHTML = fileName;
    this.errorContainerTarget.innerHTML = "";
  }

  showError(e) {
    if(this.inputFileTarget.files.length === 0) {
      e.preventDefault();
      this.errorContainerTarget.innerHTML = "Veuillez ajouter un fichier.";
    }
    else {
      this.errorContainerTarget.innerHTML = "";
    }
  }
}
