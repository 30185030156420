import { Controller } from "@hotwired/stimulus"
import log from "tailwindcss/lib/util/log";

export default class extends Controller {
  static targets = [
    "netChargeAmount",
    "deliveryQuantity",
    "discountActualAmount",
    "totalAmount",
    "totalBilled",
    "vatPercentage",
    "product"
  ];


  calculateAmounts() {
    // Calculate total amount
    let newTotalAmount = parseFloat(this.netChargeAmountTarget.value) * parseFloat(this.deliveryQuantityTarget.value);
    this.totalAmountTarget.value = newTotalAmount.toFixed(2);
    // Calculate total billed
    let newTotalBilled = newTotalAmount;
    if(parseFloat(this.discountActualAmountTarget.value)) {
      newTotalBilled = newTotalBilled - parseFloat(this.discountActualAmountTarget.value);
    }
    newTotalBilled = newTotalBilled + (newTotalBilled * (parseFloat(this.vatPercentTargetage.value) / 100));
    this.totalBilledTarget.value = newTotalBilled.toFixed(2);
  }
}
