import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase"
export default class extends Controller {
  static targets = ["netTotalAmount", "vat", "grossTotalAmount"]
  connect() {
  }

  roundNumber(number) {
    return Math.round(number * 100) / 100;
  }

  caculateTTC(){
    let vat;

    if (this.vatTarget.value == "") {
      vat = 0;
    }
    else {
      vat = parseFloat(this.vatTarget.value);
    }

    let totalUnit = ((parseFloat(this.netTotalAmountTarget.value)) * (1 + vat / 100))
    this.grossTotalAmountTarget.value = this.roundNumber(totalUnit);
  }
}
