import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = [ "companyCheckbox", "companyFormFields", "companyName", "name", "nameFormFields" ]

  connect() {
  }

  copyNameToCompanyName() {
    this.companyNameTarget.value = this.nameTarget.value
    this.companyNameTarget.setAttribute("value", this.nameTarget.value)
  }

  nameChanged() {
    if (this.companyCheckboxTarget.checked == false) {
      this.copyNameToCompanyName()
    }
  }

  disableAutocompleteFor(target) {
    const autocomplete_controller = this.application.getControllerForElementAndIdentifier(target, "autocomplete")
    autocomplete_controller.disconnect()
  }

  enableAutocompleteFor(target) {
    const autocomplete_controller = this.application.getControllerForElementAndIdentifier(target, "autocomplete")
    autocomplete_controller.connect()
  }

  toggle() {
    this.copyNameToCompanyName()

    if (this.companyCheckboxTarget.checked == true) {
      this.companyFormFieldsTarget.classList.remove("hidden")
      this.disableAutocompleteFor(this.nameFormFieldsTarget)
      this.enableAutocompleteFor(this.companyFormFieldsTarget)
    } else {
      this.companyFormFieldsTarget.classList.add("hidden")
      this.disableAutocompleteFor(this.companyFormFieldsTarget)
      this.enableAutocompleteFor(this.nameFormFieldsTarget)
    }
  }
}
