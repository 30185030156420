import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-validation"
export default class extends Controller {
  static targets = ["input", "errorMessage"];

  connect() {
    this.notNullValidation();
  }

  betweenValidation() {
    let input = this.inputTarget;
    const errorMessage = this.errorMessageTarget;
    const minLength = parseInt(input.getAttribute("minlength"));
    const maxLength = parseInt(input.getAttribute("maxlength"));
    const inputName = input.getAttribute("data-name");
    if (input.value === "") {
      errorMessage.innerHTML = "";
    } else if (
      input.value.length < minLength ||
      input.value.length > maxLength
    ) {
      if (minLength === maxLength) {
        errorMessage.innerHTML = `${inputName} doit être de ${minLength} caractères.`;
      } else {
        errorMessage.innerHTML = `${inputName} doit être compris entre ${minLength} et ${maxLength}.`;
      }
    } else {
      errorMessage.innerHTML = "";
    }
  }

  vatNumberValidation() {
    let inputValue = this.inputTarget.value;
    this.frenchVatFormat(inputValue);
  }

  frenchVatFormat(value) {
    const errorMessage = this.errorMessageTarget;
    let form = document.getElementById("entity");
    let startsWith = this.inputTarget.getAttribute("data-startsWith");
    if (value === "") {
      errorMessage.innerHTML = "";
    } else if (
      value.startsWith(startsWith) &&
      value.length === 13 &&
      !isNaN(value.substr(2))
    ) {
      errorMessage.innerHTML = "";
    } else {
      errorMessage.innerHTML = "Le numéro de TVA n'est pas au bon format.";
    }

    if (errorMessage.innerHTML != "") {
      this.blockSubmit(form);
    } else {
      form.addEventListener("submit", () => {
        form.submit();
      });
    }
  }

  blockSubmit(form) {
    form.addEventListener("submit", (e) => {
      e.preventDefault();
    });
  }

  notNullValidation(e) {
    this.element.addEventListener("submit", (e) => {
      e.preventDefault();
      let input = this.inputTarget;
      if (input.value === "") {
        this.errorMessageTarget.innerHTML = "Ce champ est obligatoire.";
      } else {
        this.element.submit();
      }
    });
  }

  nafCodeFormat(value) {
    const regex = /^\d{4}[a_zA-Z]$/;

    return regex.test(value);
  }

  nafCodeValidation() {
    let inputValue = this.inputTarget.value;
    let isValid = this.nafCodeFormat(inputValue);

    if (inputValue === "") {
      this.errorMessageTarget.innerHTML = "";
    } else if (!isValid) {
      this.errorMessageTarget.innerHTML = "Le code NAF n'est pas au bon format.";
    } else {
      this.errorMessageTarget.innerHTML = "";
    }

  }

}
