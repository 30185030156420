import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['hiddenField', 'form'];

    toggleHiddenField(event) {
        const button = event.currentTarget;
        const isDeactivate = button.dataset.activate === 'true';
        this.hiddenFieldTarget.value = isDeactivate ? true : false;
        this.formTarget.requestSubmit();
    }
}