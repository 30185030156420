import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fields"];

  connect() {}

  resetFormFields() {
    console.log("tetst");
    this.fieldsTargets.forEach((field) => {
      if (field.type === "checkbox") {
        field.removeAttribute("checked");
      } else if (field.type === "radio") {
        field.removeAttribute("checked");
      } else {
        field.value = "";
      }
    });

    let form = document.getElementById("filters-form");
    form.submit();
  }
}
