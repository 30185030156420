import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-customer"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_customer: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#new",
          popover: {
            description:
              "Pour créer un nouveau contact, vous pouvez cliquer sur ce bouton.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#edit",
          popover: {
            description:
              "Pour modifier les informations, vous pouvez cliquer sur ce bouton.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#delete",
          popover: {
            description:
              "Vous pouvez supprimer la fiche client, en cliquant ici, s'il n'est pas déjà renseigner sur un document.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#invoice",
          popover: {
            description: "Vous pouvez créer une facture, en cliquant ici.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#quotation",
          popover: {
            description: "Vous pouvez créer un devis, en cliquant ici.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#transfer",
          popover: {
            description:
              "Vous pouvez transférer les factures à votre client en cliquant sur ce bouton. Il recevra un lien avec des factures le concernant.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
