import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["bankRequiredField", "mandateRequiredField"]

    validate(event){
        let filledBankField = false;
        let filledMandateField = false;

        this.bankRequiredFieldTargets.forEach(field => {
            if (field.value.trim() === "") {
                filledBankField = true;
            }
        });
        this.mandateRequiredFieldTargets.forEach(field => {
            if (field.value.trim() === "") {
                filledMandateField = true;
            }
        });

        if(filledBankField && filledMandateField){
            if(!confirm("Êtes-vous sûr de vouloir continuer ? Les champs de coordonnées bancaires sont vides.")){
                event.preventDefault()
            }
        }
    }
}

