import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="document-mode"
export default class extends Controller {
  static targets = ["simpleModeCheckbox", "expertField"];

  connect() {
    if (this.simpleModeCheckboxTarget.checked) {
      this.hideExpertFields();
    }
  }

  toggle() {
    this.expertFieldTargets.forEach((field) => {
      field.classList.toggle("hidden");
    });
  }

  showExpertFields() {
    this.expertFieldTargets.forEach((field) => {
      field.classList.remove("hidden");
    });
  }

  hideExpertFields() {
    this.expertFieldTargets.forEach((field) => {
      field.classList.add("hidden");
    });
  }
}
