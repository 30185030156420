// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import { Autocomplete } from 'stimulus-autocomplete'
application.register('autocomplete', Autocomplete)

import CharacterCounter from 'stimulus-character-counter'
application.register('character-counter', CharacterCounter)

import CheckboxSelectAll from 'stimulus-checkbox-select-all'
application.register('checkbox-select-all', CheckboxSelectAll)

import ColorPicker from 'stimulus-color-picker'
application.register('color-picker', ColorPicker)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)

import Notification from 'stimulus-notification'
application.register('notification', Notification)

import PasswordVisibility from 'stimulus-password-visibility'
application.register('password-visibility', PasswordVisibility)

import Reveal from 'stimulus-reveal-controller'
application.register('reveal', Reveal)

import Sortable from 'stimulus-sortable'
application.register('sortable', Sortable)

import TextAreaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextAreaAutogrow)
