import { Controller } from "@hotwired/stimulus"

const nameValidationTarget = document.getElementById("name-validation")

export default class extends Controller {
  static targets = [
    "siren",
    "businessName",
    "nafCode",
    "name",
    "search",
    "infos",
    "tva",
    "establishmentAddress",
    "establishmentPostalCode",
    "establishmentSiret",
    "establishmentCity",
    "establishmentCountryCode",
    "container",
    "template",
    "createEstablishmentButton",
    "establishmentSiret",
    "establishmentLineTwo",
    "establishmentHeadquarter",
    "loader",
    "destroyInput",
    "card",
    "deleteIcon",
    "addIcon",
    "chosenEstablishments",
    "chosenEstablishmentsSection",
    "errorMessageName",
    "errorMessageSiren",
    "autocompleteNameResult",
    "autocompleteSirenResult",
    "nameControllerAutocomplete",
    "errorMessageEstablishment",
  ]

  connect() {
  }

  showLoader(loader, status) {
    loader.style.display = "flex"

    setTimeout(() => {
      if(status == "200"){
        loader.style.display = "none"
      }
      else{
        alert("Nous n'avons pas pu trouver d'établissement correspondant")
        loader.style.display = "none"
      }
    }, "2000")
  }

  createEstablishments(establishments_json) {
    for(let index = 0; index < establishments_json.length; index++){

      let establishment_json = establishments_json[index]

      var content = this.templateTarget
      content = content.innerHTML.replace(/NEW_RECORD/g, index)
      this.containerTarget.insertAdjacentHTML('beforebegin', content)

      this.establishmentCityTargets[index].value = establishment_json.libelleCommuneEtablissement

      this.establishmentPostalCodeTargets[index].value = establishment_json.codePostalEtablissement
      this.establishmentSiretTargets[index].value = establishment_json.siret

      if(establishment_json.complementAdresseEtablissement != null)
      {
        this.establishmentLineTwoTargets[index].value = establishment_json.complementAdresseEtablissement
      }

      if(establishment_json.etablissementSiege == "true")
      {
        this.establishmentHeadquarterTargets[index].value = true
      }

      var address = ""
      if(establishment_json.numeroVoieEtablissement != null) {
        address += establishment_json.numeroVoieEtablissement
      }
      if(establishment_json.indiceRepetitionEtablissement != null) {
        address += " " + establishment_json.indiceRepetitionEtablissement
      }
      if(establishment_json.typeVoieEtablissement != null) {
        address += " " + establishment_json.typeVoieEtablissement
      }
      if(establishment_json.libelleVoieEtablissement != null) {
        address += " " + establishment_json.libelleVoieEtablissement
      }

      this.establishmentAddressTargets[index].value = address
    }
  }

  destroyEstablishments() {
    this.cardTargets.forEach(function(card) {
      card.remove();
    });
  }

  createEstablishmentsFromName(event) {
    event.preventDefault()

    this.sirenTarget.value = event.currentTarget.getAttribute("data-siren")
    this.fetchEstablishmentsFromSiren(this.sirenTarget.value)
  }

  createEstablishmentsFromSiren(event) {
    event.preventDefault()

    this.fetchEstablishmentsFromSiren(this.sirenTarget.value)
  }

  fetchEstablishmentsFromSiren(siren) {
    fetch(`/legal_units/siren_complete?q=${siren}` , {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        response.json()
          .then(data => {
            this.showLoader(this.loaderTarget, response.status)
            this.businessNameTarget.value = data.nomUniteLegale
            this.nameTarget.value = data.denominationUniteLegale

            let naf = data.activitePrincipaleUniteLegale
            let cleanNaf = naf.replace('.', '')
            this.nafCodeTarget.value = cleanNaf

            let calculTva = 12 + 3 * (parseInt(siren) % 97) % 97
            this.tvaTarget.value = `FR${calculTva}${siren}`

            this.destroyEstablishments()
            data.etablissements = data.etablissements.filter(establishment => establishment.etatAdministratifEtablissement === "A")
            this.createEstablishments(data.etablissements.slice(0, 35))
          })
      })
  }

  createEmptyEstablishment() {
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforebegin', content)
  }

  addAssociation(event) {    
    if(this.chosenEstablishmentsTarget.innerHTML === "") {
      this.chosenEstablishmentsTarget.classList.add("grid", "lg:grid-cols-4", "my-4", "gap-4", "added")
      this.chosenEstablishmentsSectionTarget.classList.remove("hidden")
    }

    let customerEstablishmentCard = event.target.closest(".customer-establishment-card")
    let destroy = customerEstablishmentCard.querySelector(".destroy")
    destroy.value = false

    this.chosenEstablishmentsTarget.appendChild(customerEstablishmentCard)

    // Toggle buttons
    event.target.closest(".customer-establishment-add-icon").classList.add("hidden")
    customerEstablishmentCard.querySelector(".customer-establishment-remove-icon").classList.remove("hidden")
    this.checkForCards();
  }

  removeAssociation(event) {
    this.destroyInputTarget.value = true

    let customerEstablishmentCard = event.target.closest(".customer-establishment-card")
    let destroy = customerEstablishmentCard.querySelector(".destroy")
    destroy.value = true

    this.containerTarget.before(customerEstablishmentCard)

    this.chosenEstablishmentsTarget.classList.remove("added")

    // Toggle buttons
    event.target.closest(".customer-establishment-remove-icon").classList.add("hidden")
    customerEstablishmentCard.querySelector(".customer-establishment-add-icon").classList.remove("hidden")

    if(this.chosenEstablishmentsTarget.innerHTML ===  "") {
      this.chosenEstablishmentsSectionTarget.classList.add("hidden")
    }
  }

  resetFields(event) {
    event.preventDefault()

    this.businessNameTarget.value = ""
    this.nameTarget.value = ""
    this.nafCodeTarget.value = ""
    this.sirenTarget.value = ""
    this.tvaTarget.value = ""
    this.errorMessageNameTarget.innerHTML = ""
    this.destroyEstablishments()
  }


  checkName(){
    let name = this.nameTarget.value
    if(name != ""){
      fetch(`/customers?name=${name}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json())
        .then(data => {
          if(data.exists){
            this.errorMessageNameTarget.innerHTML = `Cette entité semble déjà exister. <a href="/customers/${data.legal_unit.linkable_id}">Voir</a>`
          }
          else{
            this.errorMessageNameTarget.innerHTML = ""
          }
        })
    }
    if(name == ""){
      this.errorMessageNameTarget.innerHTML = ""
    }
  }

  checkSiren(){
    let siren = this.sirenTarget.value
    if(siren != ""){
      fetch(`/customers?siren=${siren}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json())
        .then(data => {
          if(data.exists == true){
            this.errorMessageSirenTarget.innerHTML = `Cette entité semble déjà exister. <a href="/customers/${data.legal_unit.linkable_id}">Voir</a>`
            this.autocompleteSirenResultTarget.classList.add("hidden")
          }
          else{
            this.errorMessageSirenTarget.innerHTML = ""
            this.autocompleteSirenResultTarget.classList.remove("hidden")
          }
        })
    }
    if(siren == ""){
      this.errorMessageSirenTarget.innerHTML = ""
    }
  }

  async validateName(name, nameValidationTarget) {
    const timestamp = new Date().getTime()

    try {
      const response = await fetch(`/customers?name=${name}&timestamp=${timestamp}`, {
        headers: { 'Turbo-Frame': 'name-validation', 'Accept': 'text/vnd.turbo-stream.html' }
      });

      if (response.ok) {
        // Affiche les messages d'erreur renvoyés par Turbo Stream
        const turboStreamResponse = await response.text();
        nameValidationTarget.innerHTML = turboStreamResponse;
      }
      else {
        console.error('La requête de validation a échoué.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  clearValidation(nameValidationTarget) {
    nameValidationTarget.innerHTML = '';
  }

  submit(event) {
    const customerCards = this.chosenEstablishmentsTarget.querySelectorAll(".customer-establishment-card");
    if (customerCards.length === 0){
      event.preventDefault(); // Pour éviter le rechargement de la page
    }

    this.checkForCards();
  }

  checkForCards() {
    const customerCards = this.chosenEstablishmentsTarget.querySelectorAll(".customer-establishment-card");

    // Si aucune div avec la classe customer-establishment-card n'est trouvée, afficher le message d'erreur
    if (customerCards.length === 0) {
      this.errorMessageEstablishmentTarget.classList.remove("hidden");
    } else {
      this.errorMessageEstablishmentTarget.classList.add("hidden");
    }
  }
}

