import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "bg", "personalised", "admin" ]

  toggle() {
    this.bgTarget.classList.toggle("translate-x-5")
    this.bgTarget.classList.toggle("translate-x-0")
    this.personalisedTarget.classList.toggle("hidden")
    this.adminTarget.classList.toggle("hidden")
  }

}
