import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-new-invoice-information"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_new_invoice_information: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    if (document.getElementById("document_simple_mode").checked) {
      this.driveSimple();
    } else {
      this.driveExpert();
    }
  }

  driveSimple() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#project_name",
          popover: {
            description: "Renseignez le nom du projet.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#order_reference",
          popover: {
            description: "Renseignez l'identifiant du bon de commande.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#date",
          popover: {
            description: "Choisissez la date de facturation.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#due_type_code",
          popover: {
            description:
              "Sélectionnez l'échéance (à définir dans les paramétrages de facturation).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#expert",
          popover: {
            description:
              "Vous pouvez décocher le mode simple pour renseigner des informations complémentaires concernant votre facture",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }

  driveExpert() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#project_name",
          popover: {
            description: "Renseignez le nom du projet.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#order_reference",
          popover: {
            description: "Renseignez l'identifiant du bon de commande.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#subject",
          popover: {
            description: "Renseignez une description/objet.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#billing_start_date",
          popover: {
            description: "Choisissez la date de début de prestation.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#billing_end_date",
          popover: {
            description: "Choisissez la date de fin de prestation.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#invoice_reference_date",
          popover: {
            description:
              "Choisissez la date de facture antérieure si cette information est disponible.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#date",
          popover: {
            description: "Choisissez la date de facturation.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#due_type_code",
          popover: {
            description:
              "Sélectionnez l'échéance (à définir dans les paramétrages de facturation).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#recurrence",
          popover: {
            description: "Sélectionnez le type d'abonnement et sa périodicité.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#note",
          popover: {
            description:
              "Sélectionnez un motif de note de la facture et renseignez les informations associées.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
