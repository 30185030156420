import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["siren"]

  autocompleteLegalUnitWithName(event) {
    event.preventDefault()

    this.sirenTarget.value = event.currentTarget.getAttribute("data-siren")
  }
}
