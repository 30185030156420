import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-validation"
export default class extends Controller {
  static targets = [ "startDate", "endDate", "errorMessage" ]

  connect() {
    this.validate();
  }

  validate(event) {
    if (this.startDateTarget.value && this.endDateTarget.value) {
      if (this.startDateTarget.value > this.endDateTarget.value) {
        this.errorMessageTarget.innerHTML = "La date de début doit être antérieure à la date de fin.";
        event.preventDefault();
      } else {
        this.errorMessageTarget.innerHTML = "";
      }

      let form = document.getElementById('form');
      if(form) {
        form.addEventListener('submit', (e)=>{
          if (this.startDateTarget.value > this.endDateTarget.value) {
            e.preventDefault();
          }
        });
      }
      else{
        this.element.addEventListener('submit', (e)=>{
          if (this.startDateTarget.value > this.endDateTarget.value) {
            e.preventDefault();
          }
        });
      }
    }
  }
}
