import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

// Connects to data-controller="product-sortable"
export default class extends Controller {
  static targets = ["position"];

  connect() {
    this.sortable = new Sortable(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      handle: ".handle",
    });
  }

  end(event) {
    const id = event.item.dataset.id;
    const position = event.newIndex;
    let elementArray = this.sortable.toArray();
    let elementOrder = [];

    for (let i = 0; i < elementArray.length; i++) {
      elementOrder.push({
        id: elementArray[i],
        position: i,
      });
    }

    this.positionTargets.forEach((target) => {
      const product = elementOrder.find(
        (product) => product.id == target.dataset.id,
      );

      let item = document.getElementById(product.id);
      let positionInput = item.querySelector(".position-field");
      positionInput.value = product.position + 1;
    });
  }

  updatePositions(){
    let elementArray = this.sortable.toArray();
    let elementOrder = [];

    for (let i = 0; i < elementArray.length; i++) {
      elementOrder.push({
        id: elementArray[i],
        position: i,
      });
    }

    this.positionTargets.forEach((target) => {
      const product = elementOrder.find(
        (product) => product.id == target.dataset.id,
      );

      let item = document.getElementById(product.id);
      let positionInput = item.querySelector(".position-field");
      positionInput.value = product.position + 1;
    });
  
  }

  moveUp(event) {
    const item = event.target.closest(".nested-fields");
    let previousItem = item.previousElementSibling;
    
    while (previousItem && previousItem.tagName != "LI") {
      previousItem = previousItem.previousElementSibling;
    }

    if (previousItem) {
      item.parentNode.insertBefore(item, previousItem);
      this.updatePositions();
    }
  }
  moveDown(event) {
    const item = event.target.closest(".nested-fields");
    let nextItem = item.nextElementSibling;
    
    while (nextItem && nextItem.tagName != "LI") {
      nextItem = nextItem.nextElementSibling;
    }

    if (nextItem) {
      item.parentNode.insertBefore(nextItem, item);
      this.updatePositions();
    }
  }
}
