import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";
import help_import_export_contacts_controller from "./help_import_export_contacts_controller";

// Connects to data-controller="help-import-export-products"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_import_export_products: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#import-model",
          popover: {
            description:
              "Vous pouvez télécharger le modèle d'import des articles.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#import-file",
          popover: {
            description:
              "Ajouter votre fichier d'import de vos articles au format CSV.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#import-btn",
          popover: {
            description:
              "Cliquez sur Importer pour choisir le fichier à importer sur BillyGen",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#export-file",
          popover: {
            description:
              "Cliquez sur ici pour exporter vos articles au format CSV.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
