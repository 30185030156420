import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bulk-checkbox"
export default class extends Controller {
  static targets = ["button", "checkbox", "form", "selectAll", "actionsContainer"];

  connect() {
    this.checked();
  }

  get(e) {
    e.preventDefault();
    this.formTarget.method = "get";
    this.formTarget.submit();
    this.formTarget.method = "post";
  }

  // Toggles all checkboxes based upon what is currently checked
  toggleSelectAll() {
    this.selectAllTarget.checked ? this.selectAll() : this.unselectAll();
  }

  // Selects all checkboxes
  selectAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = true));
    this.buttonTargets.forEach((button) => {
      button.classList.remove("b-btn-disabled");
      button.classList.add("b-btn-primary");
      button.disabled = false;
    });
  }

  // Unselects all checkboxes
  unselectAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
    this.buttonTargets.forEach((button) => {
      button.classList.remove("b-btn-primary");
      button.classList.add("b-btn-disabled");
      button.disabled = true;
    });
  }

  toggleContainer(){
    if (this.checkboxTargets.filter((checkbox) => checkbox.checked).length > 0) {
      this.actionsContainerTarget.classList.toggle("hidden");
    } else {
      this.actionsContainerTarget.classList.toggle("hidden");
    }
  }

  checked() {
    const checked =
      this.checkboxTargets.filter((checkbox) => checkbox.checked).length > 0;
    if (checked) {
      this.buttonTargets.forEach((button) => {
        button.classList.remove("b-btn-disabled");
        button.classList.add("b-btn-primary");
        button.disabled = false;

        switch (button.name) {
          case "numbered":
            button.setAttribute(
              "data-turbo-confirm",
              "Êtes-vous sûr(e) de vouloir numéroter ces factures ?",
            );
            break;
          case "destroy_invoices":
            button.setAttribute(
              "data-turbo-confirm",
              "Êtes-vous sûr(e) de vouloir supprimer ces factures ?",
            );
            break;
        }
      });
    } else {
      this.buttonTargets.forEach((button) => {
        button.classList.remove("b-btn-primary");
        button.classList.add("b-btn-disabled");
        button.removeAttribute("data-turbo-confirm");
        button.disabled = true;
      });
    }
  }
}
