import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

// Connects to data-controller="archive-folder-sortable"
export default class extends Controller {
  static targets = ["position"];

  connect() {
    this.sortable = new Sortable(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
    });
  }

  end(event) {
    const id = event.item.dataset.id;
    const position = event.newIndex;
    let elementArray = this.sortable.toArray();
    let elementOrder = [];

    for (let i = 0; i < elementArray.length; i++) {
      elementOrder.push({
        id: elementArray[i],
        position: i,
      });
    }

    this.positionTargets.forEach((target) => {
      const archiveFolder = elementOrder.find(
        (archiveFolder) => archiveFolder.id == target.dataset.id,
      );

      let item = document.getElementById(archiveFolder.id);
      let positionInput = item.querySelector(".position-field");
      positionInput.value = archiveFolder.position + 1;
    });

    let form = event.item.querySelector("#archive-folder-form");
    form.submit();
  }
}
