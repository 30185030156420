import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="archive-folder-errors"
export default class extends Controller {
static targets = [ "error", "inputName", "form" ]
static values = { type: String }
  checkNameUniqueness(event) {
    let url = "/archive_folders"
    fetch(`${url}?name=${this.inputNameTarget.value}&document_type=${this.typeValue}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data === true) {
          this.formTarget.addEventListener('submit', function(event) {
            event.preventDefault();
          });

          this.errorTarget.innerHTML = `Ce nom de dossier existe déjà.`
        } else {
          this.errorTarget.classList.add("hidden")
        }
      })
  }
}
