import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password"
export default class extends Controller {
  static targets = ["passwordInput", "error", "confirmPasswordInput", "confirmError"]

  checkPasswordLength(){
    const password = this.passwordInputTarget.value
    const errorElement = this.errorTarget

    if(password.length < 12 ){
      errorElement.textContent = "Le mot de passe est trop court (minimum 12 caractères)."
      errorElement.style.display = "block"
    }
    else{
      errorElement.textContent = ""
      errorElement.style.display = "none"
    }

    this.resetError(password, errorElement)
  }

  checkConfirmPassword(){
    const password = this.passwordInputTarget.value
    const confirmPassword = this.confirmPasswordInputTarget.value
    const errorElement = this.confirmErrorTarget

    if(password != confirmPassword){
      errorElement.textContent = "Les mots de passe ne correspondent pas."
      errorElement.style.display = "block"
    }
    else{
      errorElement.textContent = ""
      errorElement.style.display = "none"
    }

    this.resetError(confirmPassword, errorElement)

  }

  resetError(value, element){
    if(value.length === 0){
      element.textContent = ""
      element.style.display = "none"
    }
  }
}
