import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "formHidden", "inputName", "inputNumber" ,"example", "exampleH3", "issueDate", "dueDate", "dueName" ]

  connect(){
  }

  addEndOfMonth(){
    if(this.formHiddenTarget.value === "true"){
      this.formHiddenTarget.value = "false"
    }
    else{
      this.formHiddenTarget.value = "true"
    }
  }

  dueTypesExample(){
    if(this.inputNameTarget.value == ""){
      this.exampleH3Target.classList.add("hidden")
      this.exampleTarget.classList.add("hidden")
    }
    else{
      this.exampleH3Target.classList.remove("hidden")
      this.exampleTarget.classList.remove("hidden")
    }

    let due_type = this.inputNameTarget.value
    this.dueNameTarget.innerHTML = due_type
  }

  dueDateExample(){
    if(!this.inputNumberTarget.value == ""){
      let date = new Date()
      let dueDate = new Date()
      let date_end_of_month = new Date()

      let current_date = date.toLocaleDateString("fr-FR")
      dueDate.setDate((date.getDate() + parseInt(this.inputNumberTarget.value)))
      let current_due_date = dueDate.toLocaleDateString("fr-FR")
      let current_date_end_of_month = new Date(date_end_of_month.getFullYear(), date_end_of_month.getMonth() + 2, 0).toLocaleDateString("fr-FR")

      this.issueDateTarget.innerHTML = `Date d'émission : ${current_date}`

      if(this.formHiddenTarget.value === "true"){
        this.dueDateTarget.innerHTML = `Date d'échéance : ${current_date_end_of_month}`
      }
      else{
        this.dueDateTarget.innerHTML = `Date d'échéance : ${current_due_date}`
      }
    }
  }
}
