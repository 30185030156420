import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-new-product"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_new_product: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: true,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#name",
          popover: {
            description:
              "Renseignez le nom de votre article, il sera possible de le choisir pour votre facture. (exemple: Conception Graphique).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#code",
          popover: {
            description:
              "Renseignez le code de votre article. Saisissez par exemple  deux lettres - deux chiffres (exmple : CG-01).",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#validity",
          popover: {
            description:
              "Choisir la date de validité de votre article (Produit/service), valide depuis et valide jusqu'au.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#accounting_account_code",
          popover: {
            description:
              "Vous pouvez sélectionner le type de produit à l'aide de la liste déroulante.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#description",
          popover: {
            description:
              "Vous pouvez ajouter une description supplémentaire concernant votre article.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#gross_charge_amount",
          popover: {
            description: "Indiquez le prix unitaire HT de votre article.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#vat_percentage",
          popover: {
            description:
              "Indiquez le taux de TVA appliqué. Mettre zéro en cas de TVA non applicable.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#unit_code",
          popover: {
            description: "Renseigner l'unité de mesure dans le menu déroulant.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#currency_code",
          popover: {
            description:
              "Renseigner la devise (Euro est la valeur par défaut).",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
