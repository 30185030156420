import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'links', 'template', "selectProductId", "simpleModeCheckbox" ]

    connect() {
        this.totalAmountController = this.application.getControllerForElementAndIdentifier(this.element, 'total-amount')
        this.documentModeController = this.application.getControllerForElementAndIdentifier(this.element, 'document-mode')
        this.totalAmountController.connect()
        this.documentModeController.connect()
    }

    createProduct(event) {
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());

    let productId = this.selectProductIdTarget.value;
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    let inputDataValues = {};

    fetch(`/product_autocomplete/${productId}`, {
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
            },
        })
        .then((response) => response.json())
        .then((data) => {
            inputDataValues = {
                product: data[0].name,
                description: data[0].description,
                accountingAccountCode: data[0].accounting_account_code,
                documentItemCode: data[0].code,
                vatPercentage: data[0].vat_percentage.toFixed(1),
                startDate: data[0].valid_since,
                endDate: data[0].valid_until,
                grossChargeAmount: data[0].gross_charge_amount_cents / 100,
                netChargeAmount: data[0].gross_charge_amount_cents / 100,
                deliveryQuantity: data[0].basis_quantity != null ? data[0].basis_quantity : 1,
                unitCode: data[0].unit_code,
                productId: data[0].id,
            };

            const templateDiv = document.createElement('div');
            templateDiv.innerHTML = content;

            const inputElements = templateDiv.querySelectorAll('[data-price-target]');
            inputElements.forEach((inputElement) => {
                const attributeName = inputElement.getAttribute('data-price-target');
                if (attributeName && inputDataValues.hasOwnProperty(attributeName)) {
                    switch (attributeName) {
                        case "unitCode":
                            for (let i = 0; i < inputElement.options.length; i++) {
                                if (inputElement.options[i].value == inputDataValues[attributeName]) {
                                    inputElement.options[i].setAttribute("selected", true);
                                }
                            }
                            break;
                        case "description":
                            inputElement.innerHTML = inputDataValues[attributeName];
                            break;
                        case "vatPercentage":
                            for (let i = 0; i < inputElement.options.length; i++) {
                                if (inputElement.options[i].value == inputDataValues[attributeName]) {
                                    inputElement.options[i].setAttribute("selected", true);
                                }
                            }
                            break;
                    }
                    inputElement.setAttribute("value", inputDataValues[attributeName])          
                }
            });
            this.linksTarget.insertAdjacentHTML('beforebegin', templateDiv.innerHTML);
            this.totalAmountController.calculateTotalAmount();
            this.remove_first_blank_association();
        })
        .catch((error) => {
            console.error('Erreur lors de la récupération des données du produit : ', error);
        });
    }
    
    remove_first_blank_association()
    {
        let nested_field = document.querySelectorAll('.nested-fields')[0]
        let first_input_name = nested_field.querySelector('input[name*="document[document_items_attributes][0][name]"]')
        if (first_input_name.value == '') {
            nested_field.remove()
        }
    }

    add_association(event) {
        event.preventDefault()
        let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)
        if(this.simpleModeCheckboxTarget.checked) {
            this.documentModeController.hideExpertFields()
        }
    }

    remove_association(event) {
        event.preventDefault()

        let wrapper = event.target.closest('.nested-fields')
        if (wrapper.dataset.newRecord == 'true') {
            wrapper.remove()
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
}
