import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inputDefault" ]

  connect() {
  }

  defaultValue(){
    //this.inputDefaultTarget.value = this.inputDefaultTarget.defaultValue;
  }
}
