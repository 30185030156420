import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "button", "checkbox", "documentIds"]

  toggle(e) {
    event.preventDefault()
    if(!this.buttonTarget.classList.contains("b-btn-disabled")){
      this.containerTarget.classList.toggle("hidden")
    }
  }
  updateDocumentsIds(){
    const selectedInvoices = this.checkboxTargets.filter(checkbox => checkbox.checked)
    const documentIds = selectedInvoices.map(checkbox => checkbox.value)
    this.documentIdsTarget.value = documentIds
  }
}
