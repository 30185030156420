import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="destroy-popup"
export default class extends Controller {
  static targets = ["container", "button"]

  toggle(e) {
    if(!this.buttonTarget.classList.contains("b-btn-disabled")) {
      this.containerTarget.classList.toggle("hidden")
    }
  }
}
