import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "button", "bg", "fadeout", "fadein" ]

    toggle() {
        this.buttonTarget.classList.toggle("bg-secondary-500")
        this.buttonTarget.classList.toggle("bg-gray-200")
        this.bgTarget.classList.toggle("translate-x-5")
        this.bgTarget.classList.toggle("translate-x-0")
        this.fadeoutTarget.classList.toggle("opacity-0")
        this.fadeoutTarget.classList.toggle("ease-out")
        this.fadeoutTarget.classList.toggle("duration-100")
        this.fadeoutTarget.classList.toggle("opacity-100")
        this.fadeoutTarget.classList.toggle("ease-in")
        this.fadeoutTarget.classList.toggle("duration-200")
        this.fadeinTarget.classList.toggle("opacity-0")
        this.fadeinTarget.classList.toggle("ease-out")
        this.fadeinTarget.classList.toggle("duration-100")
        this.fadeinTarget.classList.toggle("opacity-100")
        this.fadeinTarget.classList.toggle("ease-in")
        this.fadeinTarget.classList.toggle("duration-200")
    }

}