import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "dateField", "dateError" , "form"]

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("submit", this.showErrors.bind(this))
    }
  }

  toggle(e) {
    this.containerTarget.classList.toggle("hidden")
  }

  // Deprecated
  togglePopUp() {
    this.toggle()
  }

  showErrors(event){
    if(this.dateFieldTarget.value == "") {
      event.preventDefault()
      this.dateErrorTarget.innerHTML = "Veuillez sélectionner une date."
    }
    else{
      this.dateErrorTarget.innerHTML = ""
    }
  }
}
