import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cashForm", "checkLabel", "directDebitForm", "otherLabel", "selectField", "transferForm" ]

  connect(){
    this.toggleForm()
  }

  hiddeForm(show, hiddeOne, hiddeTwo){
    show.classList.remove("hidden")
    hiddeOne.classList.add("hidden")
    hiddeTwo.classList.add("hidden")
  }

  hiddeLabel(show, hidde){
    show.classList.remove("hidden")
    hidde.classList.add("hidden")
  }

  // FIXME with Slim select
  toggleForm(){
    let select = this.selectFieldTarget.value
    switch (true){
      case (select == 30 || select == 42 || select == 58):
        this.hiddeForm(this.transferFormTarget, this.cashFormTarget, this.directDebitFormTarget)
        showlabel = this.hiddeLabel(this.otherLabelTarget, this.checkLabelTarget)
        break;
      case (select == 49 || select == 59):
        this.hiddeForm(this.directDebitFormTarget, this.cashFormTarget, this.transferFormTarget)
        showlabel = this.hiddeLabel(this.otherLabelTarget, this.checkLabelTarget)
        break;
      case (select == "" || select == 10 || select == 48 || select == 1):
        this.hiddeForm(this.cashFormTarget, this.directDebitFormTarget, this.transferFormTarget)
        showlabel = this.hiddeLabel(this.otherLabelTarget, this.checkLabelTarget)
        break;
      case (select == 20):
        this.hiddeForm(this.cashFormTarget, this.directDebitFormTarget, this.transferFormTarget)
        showlabel = this.hiddeLabel(this.checkLabelTarget, this.otherLabelTarget);
        break;
    }
  }
}
