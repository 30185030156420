import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hidable" ]

  toggle() {
    this.hidableTargets.forEach(hidable => {
      hidable.classList.toggle("hidden")
    })
  }
}
