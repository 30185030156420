import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "chargeActualAmount",
    "grossChargeAmount",
    "totalUnit",
    "totalTTC",
    "vatPercentage",
    "basisQuantity",
  ]

  connect() {
    this.caculateTotalTTC()
  }

  calculateTotal() {
    let vat;
    
    if (this.vatPercentageTarget.value == "") {
      vat = 0;
    }
    else {
      vat = parseFloat(this.vatPercentageTarget.value);
    }

    this.totalUnitTarget.innerHTML = ((parseFloat(this.grossChargeAmountTarget.value) + parseFloat(this.chargeActualAmountTarget.value)) * (1 + vat / 100)).toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
  }

  caculateTotalTTC(){
    let vat;

    if (this.vatPercentageTarget.value == "") {
      vat = 0;
    }
    else {
      vat = parseFloat(this.vatPercentageTarget.value);
    }

    let totalUnit = ((parseFloat(this.grossChargeAmountTarget.value) + parseFloat(this.chargeActualAmountTarget.value)) * (1 + vat / 100))
    this.totalTTCTarget.innerHTML = (totalUnit * this.basisQuantityTarget.value).toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
  }
}
