import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-input"
export default class extends Controller {
  static targets = ["checkbox", "input", "container"];

  toggle() {
    if (this.checkboxTarget.checked) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.inputTarget.value = "";
      this.containerTarget.classList.add("hidden");
    }
  }
}
