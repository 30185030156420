import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
    static targets = [ "selectField", "textField"]

    slimSelectInstances = {}

    connect(){
        new SlimSelect({
            select: this.selectFieldTarget,
            events: {
                afterChange: (newValue) => {
                    this.textFieldTarget.value = newValue[0].value
                }
            },
            settings: {
              searchText: "Aucun résultat",
              searchPlaceholder: "Rechercher",
            }
        })
    }

}
