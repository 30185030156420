import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "chevron" ]

  connect(){
    document.addEventListener("click", this.handleClickOutside.bind(this))
  }

  toggle(){
    if (this.hasListTarget){
      this.listTarget.classList.toggle("hidden")
      if(this.hasChevronTarget) {
        this.chevronTarget.classList.toggle("rotate-180")
      }
    }
  }

  close(){
    if (this.hasListTarget){
      this.listTarget.classList.add("hidden")
      if(this.hasChevronTarget) {
        this.chevronTarget.classList.remove("rotate-180")
      }
    }
  }

  handleClickOutside(event){
    if(!this.element.contains(event.target)){
      this.close()
    }
  }
}

