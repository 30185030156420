import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = [
    "customerId",
    "input",
    "selectEstablishmentContainer",
    "billingEntityId",
    "establishmentId",
    "selectField",
    "billingContact",
    "billingContactContainer",
    "billingContactId",
  ];

  slimSelectInstances = {};

  connect() {
    if (this.customerIdTarget.value != "") {
      this.individualChecker(this.customerIdTarget.value);
    }
    new SlimSelect({
      select: this.selectFieldTarget,
      events: {
        afterChange: (newValue) => {
          let customerId = newValue[0].value;
          this.customerIdTarget.value = customerId;
          this.individualChecker(customerId);
        },
      },
      settings: {
        searchText: "Aucun résultat",
        searchPlaceholder: "Rechercher",
      },
    });

    if (this.customerIdTarget.value != "") {
      this.selectEstablishmentContainerTarget.classList.remove("hidden");
    }
  }

  individualChecker(customerId) {
    fetch(`/customer_autocomplete/${customerId}`, {
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.establishmentIdTarget.value = "";
        if (data[0].individual === false) {
          if (this.slimSelectInstances[this.inputTarget.id]) {
            this.updateEstablishments(customerId);
            const selectedOption =
              this.inputTarget.querySelector("option:checked");
            if (selectedOption) {
              let establishmentId = selectedOption.value;
              this.establishmentIdTarget.value = establishmentId;
            }
          } else {
            this.slimSelectInstances[this.inputTarget.id] = new SlimSelect({
              select: this.inputTarget,
              events: {
                afterChange: (newValue) => {
                  this.establishmentIdTarget.value = newValue[0].value;
                  if (this.hasBillingContactTarget) {
                    this.updateBillingEntityId(newValue[0].value);
                  }
                },
              },
              settings: {
                searchText: "Aucun résultat",
                searchPlaceholder: "Rechercher",
              },
            });

            this.updateEstablishments(customerId);
          }

          this.selectEstablishmentContainerTarget.classList.remove("hidden");
          this.inputTarget.required = true;
        } else {
          if (this.hasBillingContactContainerTarget) {
            this.billingContactContainerTarget.classList.add("hidden");
          }
          this.selectEstablishmentContainerTarget.classList.add("hidden");
          this.inputTarget.required = false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateEstablishments(customerId) {
    fetch(`/customers/${customerId}/establishments`, {
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((establishment) => {
          if (establishment.siret === null) {
            return {
              value: establishment.id,
              text: `${establishment.address.city}`,
            };
          } else {
            return {
              value: establishment.id,
              text: `${
                establishment.address.city
              } - ${establishment.siret.slice(-5)}`,
            };
          }
        });
        this.slimSelectInstances[this.inputTarget.id].setData(options);
        this.slimSelectInstances[this.inputTarget.id].setSelected(
          this.establishmentIdTarget.value
        );
      })
      .catch((error) => {
        console.error(error);
      });

    this.establishmentIdTarget.value = this.inputTarget.value;
  }

  updateBillingEntityId(establishmentId) {
    fetch(`/establishments/${establishmentId}/billing_contacts`, {
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((billingEntity) => {
          return {
            value: billingEntity.id,
            text: `${billingEntity.last_name} ${billingEntity.first_name}`,
          };
        });

        if (this.slimSelectInstances[this.billingContactTarget.id]) {
          this.slimSelectInstances[this.billingContactTarget.id].setData(
            options
          );
        } else {
          this.slimSelectInstances[this.billingContactTarget.id] =
            new SlimSelect({
              select: this.billingContactTarget,
              events: {
                afterChange: (newValue) => {
                  this.billingContactIdTarget.value =
                    this.billingContactTarget.value;
                },
              },
              settings: {
                searchText: "Aucun résultat",
                searchPlaceholder: "Rechercher",
              },
            });
          this.slimSelectInstances[this.billingContactTarget.id].setData(
            options
          );
        }

        if (this.hasBillingContactContainerTarget) {
          this.billingContactContainerTarget.classList.remove("hidden");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
