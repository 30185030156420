import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  toggle() {
    event.preventDefault()
    this.containerTarget.classList.toggle("hidden")
  }

  // Deprecated
  togglePopUp() {
    event.preventDefault()
    this.toggle()
  }
}
