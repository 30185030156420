import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-edit-legal-unit"
export default class extends Controller {
  static targets = ["autodrive"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      this.drive();
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_edit_legal_unit: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#name",
          popover: {
            description: "Vous pouvez modifier le nom de votre société.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#business_name",
          popover: {
            description: "Vous pouvez modifier le nom commercial.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#logo",
          popover: {
            description:
              "Vous pouvez insérer le logo de votre entreprise qui apparaîtra sur votre facture.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#title_color",
          popover: {
            description:
              "Vous pouvez modifier les couleurs des titres dans vos factures et devis.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#background_color",
          popover: {
            description:
              "Vous pouvez modifier la couleur de fond des entêtes du tableau des produits de vos factures et devis.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#siren",
          popover: {
            description: "Vous pouvez renseigner votre SIREN.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#legal_form",
          popover: {
            description: "Vous pouvez renseigner votre forme juridique.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#naf_code",
          popover: {
            description: "Vous pouvez renseigner votre code NAF.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#vat_number",
          popover: {
            description:
              "Vous pouvez renseigner votre numéro de TVA intracommunautaire.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#capital",
          popover: {
            description: "Vous pouvez renseigner votre capital.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#currency_code",
          popover: {
            description: "Vous pouvez sélectionner la devise.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#creditor_reference",
          popover: {
            description:
              "Vous pouvez renseigner votre ICS (identifiant créancier sepa). Son format varie de 11 à 35 caractères.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#website",
          popover: {
            description: "Vous pouvez renseigner votre site internet.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#additional_information",
          popover: {
            description:
              "Vous pouvez renseigner des informations supplémentaire.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
