import { Controller } from "@hotwired/stimulus";
import { driver } from "driver.js";

// Connects to data-controller="help-invoices"
export default class extends Controller {
  static targets = ["autodrive", "empty"];

  connect() {
    if (this.hasAutodriveTarget && this.autodriveTarget.value == "true") {
      if (this.hasEmptyTarget && this.emptyTarget.value == "true") {
        this.driveEmpty();
      } else {
        this.drive();
      }
    }
  }

  stopAutodrive() {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch("/autodrive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        autodrive: {
          help_invoices: false,
        },
      }),
    }).then((response) => response.json());
  }

  drive() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#invoiceActions",
          popover: {
            description: "Action concernant les factures de vente.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#archives",
          popover: {
            description: "Visualisez vos dossiers d'archives.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#purchase",
          popover: {
            description:
              "Ajoutez une note de frais et renseignez toutes les informations.",
            side: "bottom",
            align: "start",
          },
        },
        /*
        {
          element: "#abonments",
          popover: {
            description:
              "Visualisez vos factures en abonnements.",
            side: "bottom",
            align: "start",
          },
        },
        */
        {
          element: "#export",
          popover: {
            description: "Exportez vos factures.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#view",
          popover: {
            description: 'Visualisez la facture en cliquant sur "+".',
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#bulkSelection",
          popover: {
            description:
              "Sélectionnez vos factures pour réaliser des actions en masse.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#bulkActions",
          popover: {
            description:
              "Envoyer, dupliquez, archivez, supprimez vos factures en masse. Seulement les factures en brouillon peuvent être supprimées",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }

  driveEmpty() {
    const driverObj = driver({
      showProgress: false,
      progressText: "{{current}}/{{total}}",
      nextBtnText: "Suivant",
      prevBtnText: "Précédent",
      doneBtnText: "Terminer",
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep()) {
          this.stopAutodrive();
        }
        driverObj.destroy();
      },
      steps: [
        {
          element: "#new",
          popover: {
            description:
              "Créez un nouvel article et renseignez toutes les informations nécessaires.",
            side: "bottom",
            align: "start",
          },
        },
        {
          element: "#import",
          popover: {
            description: "Importez votre fichier d'article Excel.",
            side: "bottom",
            align: "start",
          },
        },
      ],
    });

    driverObj.drive();
  }
}
