import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        searchText: "Aucun résultat",
        searchPlaceholder: "Rechercher",
        placeholderText: "Sélectionner",
      },
      events: {
        addable: (value) => {
          return value;
        },
      },
    });
  }
}
