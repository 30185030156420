import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "accountingAccountCode",
    "addChargeIcon",
    "addDiscountIcon",
    "chargeActualAmount",
    "chargeActualAmountContainer",
    "chargeModal",
    "chargeModalChargeActualAmount",
    "chargeModalChargeActualPercentage",
    "chargeModalChargeActualAmountLabel",
    "chargeModalChargeActualPercentageLabel",
    "chargeModalChargeActualChoice",
    "discountModalDiscountActualAmount",
    "discountModalDiscountActualAmountLabel",
    "discountModalDiscountActualPercentage",
    "discountModalDiscountActualPercentageLabel",
    "discountModalDiscountActualChoice",
    "editChargeIcon",
    "editDiscountIcon",
    "deliveryQuantity",
    "description",
    "discountActualAmount",
    "discountActualAmountContainer",
    "discountModal",
    "endDate",
    "grossActualAmount",
    "grossChargeAmount",
    "documentItemCode",
    "modalNetChargeAmount",
    "netChargeAmount",
    "product",
    "productId",
    "startDate",
    "vatPercentage",
    "totalAmount",
    "totalGrossChargeAmount",
    "totalNetChargeAmount",
    "modalNetChargeAmount",
    "modalNetDiscountAmount",
    "unitCode",
    "discountValue",
    "chargeValue",
    "vatValue",
    "selectProductId",
    "selectName",
    "switch",
  ];

  lastChangedInput = null;

  connect() {
    if (
      this.grossChargeAmountTarget.value.length > 0 &&
      this.netChargeAmountTarget.value.length > 0
    ) {
      this.calculateTotals();
    }

    this.hideSelectElement();
  }

  autocomplete(e) {
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    let productId = this.productIdTarget.value;

    fetch(`/product_autocomplete/${productId}`, {
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.productTarget.value = data[0].name;
        this.accountingAccountCodeTarget.value =
          data[0].accounting_account_code;
        this.descriptionTarget.value = data[0].description;
        this.documentItemCodeTarget.value = data[0].code;
        this.vatPercentageTarget.value = data[0].vat_percentage.toFixed(1);
        this.startDateTarget.value = data[0].valid_since;
        this.endDateTarget.value = data[0].valid_until;
        this.grossChargeAmountTarget.value =
          data[0].gross_charge_amount_cents / 100;
        this.netChargeAmountTarget.value =
          data[0].gross_charge_amount_cents / 100;
        this.deliveryQuantityTarget.value =
          data[0].basis_quantity != null ? data[0].basis_quantity : 1;
        this.unitCodeTarget.value = data[0].unit_code;

        this.calculateTotals();
      });
  }

  toggleDiscountActualChoice() {
    this.discountModalDiscountActualAmountLabelTarget.classList.toggle(
      "hidden"
    );
    this.discountModalDiscountActualPercentageLabelTarget.classList.toggle(
      "hidden"
    );
    this.discountModalDiscountActualAmountTarget.classList.toggle("hidden");
    this.discountModalDiscountActualPercentageTarget.classList.toggle("hidden");
  }

  toggleDiscountModal() {
    this.discountModalTarget.classList.toggle("hidden");
  }

  toggleChargeModal() {
    this.chargeModalTarget.classList.toggle("hidden");
  }

  calculateGrossChargeAmount() {
    this.grossChargeAmountTarget.value =
      parseFloat(this.netChargeAmountTarget.value) +
      parseFloat(this.grossActualAmountTarget.value);
  }

  roundNumber(number) {
    return Math.round(number * 100) / 100;
  }

  calculateModalDiscountActualAmount() {
    if (this.discountModalDiscountActualChoiceTarget.value === "percentage") {
      let discountActual =
        (this.netChargeAmountTarget.value *
          this.deliveryQuantityTarget.value *
          this.discountModalDiscountActualPercentageTarget.value) /
        100;
      this.roundNumber(discountActual);
      this.discountModalDiscountActualAmountTarget.value = discountActual;
    } else {
      let discountActual = this.discountModalDiscountActualAmountTarget.value;
      this.roundNumber(discountActual);
      this.discountModalDiscountActualPercentageTarget.value =
        (discountActual /
          (this.netChargeAmountTarget.value *
            this.deliveryQuantityTarget.value)) *
        100;
    }
  }

  calculateModalDiscountActualPercentage() {
    let discountActualPercentage =
      (this.discountModalDiscountActualAmountTarget.value /
        (this.netChargeAmountTarget.value *
          this.deliveryQuantityTarget.value)) *
      100;
    this.roundNumber(discountActualPercentage);
    this.discountModalDiscountActualPercentageTarget.value =
      discountActualPercentage;
  }

  calculateModalChargeActualAmount() {
    let chargeActualAmount =
      (this.netChargeAmountTarget.value *
        this.deliveryQuantityTarget.value *
        this.chargeModalChargeActualPercentageTarget.value) /
      100;
    this.roundNumber(chargeActualAmount);
    this.chargeModalChargeActualAmountTarget.value = chargeActualAmount;
  }

  calculateModalChargeActualPercentage() {
    let chargeActualPercentage =
      (this.chargeModalChargeActualAmountTarget.value /
        (this.netChargeAmountTarget.value *
          this.deliveryQuantityTarget.value)) *
      100;
    this.roundNumber(chargeActualPercentage);
    this.chargeModalChargeActualPercentageTarget.value = chargeActualPercentage;
  }

  toggleChargeActualChoice() {
    this.chargeModalChargeActualAmountLabelTarget.classList.toggle("hidden");
    this.chargeModalChargeActualPercentageLabelTarget.classList.toggle(
      "hidden"
    );
    this.chargeModalChargeActualAmountTarget.classList.toggle("hidden");
    this.chargeModalChargeActualPercentageTarget.classList.toggle("hidden");
  }

  calculateNetPrice() {
    this.netChargeAmountTarget.value =
      this.grossChargeAmountTarget.value - this.grossActualAmountTarget.value;
    this.calculateTotals();
  }

  calculateModalNetChargeAmount() {
    this.modalNetChargeAmountTarget.innerHTML =
      this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value;
    this.modalNetDiscountAmountTarget.innerHTML =
      this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value;
  }

  calculateDiscount() {
    this.discountActualAmountContainerTarget.classList.remove("hidden");
    this.editDiscountIconTarget.classList.remove("hidden");
    this.addDiscountIconTarget.classList.add("hidden");

    if (this.discountModalDiscountActualChoiceTarget.value === "percentage") {
      let discountPercent =
        parseFloat(
          this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value
        ) - parseFloat(this.discountModalDiscountActualAmountTarget.value);
      this.discountValueTarget.value =
        parseFloat(
          this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value
        ) - discountPercent;
      this.discountActualAmountTarget.innerHTML =
        parseFloat(this.discountModalDiscountActualPercentageTarget.value) +
        " %";
    } else {
      let discountModalDiscountActualAmount = parseFloat(
        this.discountModalDiscountActualAmountTarget.value
      );
      this.discountValueTarget.value = discountModalDiscountActualAmount;
      this.discountActualAmountTarget.innerHTML =
        discountModalDiscountActualAmount.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });
    }

    this.calculateNetTotal();
  }

  calculateCharge() {
    this.chargeActualAmountContainerTarget.classList.remove("hidden");
    this.addChargeIconTarget.classList.add("hidden");

    if (this.chargeModalChargeActualChoiceTarget.value === "percentage") {
      let chargePercent =
        parseFloat(
          this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value
        ) + parseFloat(this.chargeModalChargeActualAmountTarget.value);
      this.chargeValueTarget.value =
        chargePercent -
        parseFloat(
          this.netChargeAmountTarget.value * this.deliveryQuantityTarget.value
        );
      this.chargeActualAmountTarget.innerHTML =
        parseFloat(this.chargeModalChargeActualPercentageTarget.value) + " %";
    } else {
      let chargeModalChargeActualAmount = parseFloat(
        this.chargeModalChargeActualAmountTarget.value
      );
      this.chargeValueTarget.value = chargeModalChargeActualAmount;
      this.chargeActualAmountTarget.innerHTML =
        chargeModalChargeActualAmount.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });
    }

    this.calculateNetTotal();
  }

  calculateTotals() {
    this.calculateModalDiscountActualAmount();

    let totalGrossChargeAmount =
      parseFloat(this.netChargeAmountTarget.value) *
      parseFloat(this.deliveryQuantityTarget.value);
    this.totalGrossChargeAmountTarget.setAttribute(
      "data-value",
      totalGrossChargeAmount
    );
    this.totalGrossChargeAmountTarget.innerHTML =
      totalGrossChargeAmount.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });

    // FIXME: Without discount or charge
    let totalNetChargeAmount =
      totalGrossChargeAmount -
      parseFloat(this.discountModalDiscountActualAmountTarget.value) +
      parseFloat(this.chargeModalChargeActualAmountTarget.value);
    this.totalNetChargeAmountTarget.setAttribute(
      "data-value",
      totalNetChargeAmount
    );
    this.totalNetChargeAmountTarget.innerHTML =
      totalNetChargeAmount.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
    if (
      this.lastChangedInput === null ||
      this.lastChangedInput === this.totalAmountTarget
    ) {
      let totalAmount =
        totalNetChargeAmount * (1 + this.vatPercentageTarget.value / 100);

      this.vatValueTarget.value =
        (totalNetChargeAmount * this.vatPercentageTarget.value) / 100;

      this.totalAmountTarget.setAttribute(
        "data-value",
        this.roundNumber(totalAmount)
      );
      this.totalAmountTarget.value = this.roundNumber(totalAmount);
    } else {
      let pourcentage = 1 + this.vatPercentageTarget.value / 100;
      let prixHT = this.totalAmountTarget.value / pourcentage;

      this.netChargeAmountTarget.setAttribute(
        "value",
        this.roundNumber(prixHT)
      );
      this.grossChargeAmountTarget.setAttribute(
        "value",
        this.roundNumber(prixHT)
      );
      this.grossChargeAmountTarget.value = prixHT.toFixed(2);

      this.totalGrossChargeAmountTarget.innerHTML = prixHT.toLocaleString(
        "fr-FR",
        {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        }
      );

      if (prixHT) {
        this.totalGrossChargeAmountTarget.setAttribute("data-value", prixHT);
      }

      this.netChargeAmountTarget.value =
        this.grossChargeAmountTarget.value - this.grossActualAmountTarget.value;

      this.totalNetChargeAmountTarget.setAttribute(
        "data-value",
        this.netChargeAmountTarget.value
      );
      this.totalNetChargeAmountTarget.innerHTML =
        this.netChargeAmountTarget.value.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });
    }
  }

  calculateNetTotal() {
    let netTotal =
      parseFloat(this.netChargeAmountTarget.value) *
        parseFloat(this.deliveryQuantityTarget.value) -
      parseFloat(this.discountModalDiscountActualAmountTarget.value) +
      parseFloat(this.chargeModalChargeActualAmountTarget.value);
    this.totalNetChargeAmountTarget.innerHTML = netTotal.toLocaleString(
      "fr-FR",
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      }
    );

    let total =
      parseFloat(this.netChargeAmountTarget.value) *
        parseFloat(this.deliveryQuantityTarget.value) -
      parseFloat(this.discountModalDiscountActualAmountTarget.value) +
      parseFloat(this.chargeModalChargeActualAmountTarget.value);
    let totalTTC = total * (1 + this.vatPercentageTarget.value / 100);
    this.totalAmountTarget.value = totalTTC;
  }

  recalculateAfterDelete() {
    this.vatValueTarget.value = 0;
    this.discountValueTarget.value = 0;
    this.chargeValueTarget.value = 0;
    this.totalGrossChargeAmountTarget.setAttribute("data-value", 0);
    this.totalNetChargeAmountTarget.setAttribute("data-value", 0);
    this.totalAmountTarget.setAttribute("data-value", 0);
    this.totalAmountTarget.setAttribute("value", 0);
  }

  calculateTotalBeforeTax() {
    let pourcentage = 1 + this.vatPercentageTarget.value / 100;
    let prixHT = this.totalAmountTarget.value / pourcentage;

    let multiple = prixHT * 100;
    const roundedNumber = Math.round(multiple) / 100;
    prixHT = roundedNumber;

    this.netChargeAmountTarget.value = prixHT;
    this.netChargeAmountTarget.setAttribute("value", prixHT);
    this.grossChargeAmountTarget.setAttribute("value", prixHT);
    this.grossChargeAmountTarget.value = prixHT;

    this.totalGrossChargeAmountTarget.innerHTML = prixHT.toLocaleString(
      "fr-FR",
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      }
    );

    if (prixHT) {
      this.totalGrossChargeAmountTarget.setAttribute("data-value", prixHT);
    }

    this.calculateNetTotal();
    this.calculateModalNetChargeAmount();
    this.calculateNetPrice();
  }

  lastChanged() {
    this.lastChangedInput = this.totalAmountTarget;
  }

  hideSelectElement() {
    if (this.productIdTarget.value === "" && this.productTarget.value != "") {
      this.selectNameTarget.classList.add("hidden");
      this.productTarget.classList.remove("hidden");
    }
  }

  selectProductId() {
    this.productTarget.value = this.selectNameTarget.value;
    this.productIdTarget.value = this.selectNameTarget.value;
  }
}
